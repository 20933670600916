<template>
  <div>
    <h5 class="mb-10 font-weight-bolder" v-translate>Contact details</h5>

    <!-- Name & Active -->
    <div class="row">
      <FormGroupInput
        v-model="syncedPartnerForm.Partner.Name"
        label="Name"
        input-id="partnerName"
        placeholder="Name"
        class="col-7"
        input-class="font-size-h2
      font-weight-bold"
        required
        :input-style="{ border: syncedPartnerForm.Partner?.Name == '' ? '2px solid #e74a3b' : '' }"
      />

      <FormGroupInput
        v-model="syncedPartnerForm.Partner.Reference"
        label="Reference"
        input-id="partnerReference"
        placeholder="Reference"
        class="col-3"
        required
        input-class="font-size-h2"
        :input-style="{ border: isDuplicateReference || syncedPartnerForm.Partner?.Reference == '' ? '2px solid #e74a3b' : '' }"
      />

      <div class="form-group col-2 d-flex flex-column justify-content-between align-items-end">
        <div>
          <!-- <span class="switch --switch-success switch-lg py-2">
            <label class="font-weight-bold" for="partnerIsActive"
              >Is Partner active ?
              <input
                type="checkbox"
                id="partnerIsActive"
                name="select"
                :checked="typeof this.syncedPartnerForm.Partner.Attributes !== 'undefined' ? this.syncedPartnerForm.Partner.Attributes.includes('isActive') : false"
                @change="
                  checked => {
                    $emit('update', {
                      isActive: checked
                    });
                  }
                "
              />
              <span></span>
            </label>
          </span> -->
          <span class="font-weight-bold" v-translate>Activity</span>
          <b-form-checkbox
            :checked="typeof this.syncedPartnerForm.Partner.Attributes !== 'undefined' ? this.syncedPartnerForm.Partner.Attributes.includes('isActive') : false"
            name="partnerIsActive"
            switch
            size="lg"
            class="mt-5"
            @change="
              (checked) => {
                $emit('update', {
                  isActive: checked,
                });
              }
            "
          >
            <translate>Is partner active ?</translate>
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <!-- MainRoles -->
    <div class="form-group mt-1">
      <label class="font-weight-bold" v-translate>Choose role <span class="required">*</span></label>
      <div class="row">
        <div v-for="(partnerType, index) in partnerMainRoleTypes" :key="index" class="col">
          <label
            v-if="partnerType.id !== 'AD' || syncedPartnerForm.Partner.MainRole === 'AD'"
            class="option"
            :class="syncedPartnerForm.Partner.MainRole === partnerType.id ? `bg-${partnerType.class}` : ''"
          >
            <span class="option-control mr-4">
              <span class="radio radio-lg" :class="`radio-${partnerType.class}`">
                <input
                  type="radio"
                  :value="partnerType.id"
                  :checked="syncedPartnerForm.Partner.MainRole === partnerType.id ? 'checked' : ''"
                  v-model="syncedPartnerForm.Partner.MainRole"
                  @input="
                    $emit('update', {
                      MainRole: syncedPartnerForm.Partner.MainRole,
                    })
                  "
                />
                <span></span>
              </span>
            </span>
            <span class="option-label" :class="syncedPartnerForm.Partner.MainRole === partnerType.id ? 'text-white' : ''">
              <span class="option-head">
                <span
                  class="svg-icon svg-icon-2x mr-2"
                  :class="syncedPartnerForm.Partner.MainRole === partnerType.id ? 'svg-icon-white' : `svg-icon-${partnerType.class}`"
                >
                  <!--begin::Svg Icon -->
                  <inline-svg :src="partnerType.icon"></inline-svg>
                  <!--end::Svg Icon-->
                </span>

                <span
                  class="option-title font-size-h4 font-weight-bolder w-100"
                  :class="syncedPartnerForm.Partner.MainRole === partnerType.id ? 'text-white' : `text-${partnerType.class}`"
                  >{{ $gettext(partnerType.name) }}</span
                >
                <!-- <span
                        class="option-focus symbol symbol-40 mr-3"
                        :class="`symbol-light-${partnerType.class}`"
                      >
                        <span
                          class="symbol-label font-size-h5 font-weight-bolder"
                          >{{ getFirstLetter(partnerType.id) }}</span
                        >
                      </span> -->
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>

    <!-- Address line 1 -->
    <div class="row mb-3">
      <FormGroupInput v-model="syncedPartnerForm.PartnerDetails.Address1" label="Address" input-id="partnerAddress" placeholder="Address" class="col" />
    </div>

    <!-- Address line 2 -->
    <div class="row mb-3">
      <FormGroupInput
        v-model="syncedPartnerForm.PartnerDetails.Address2"
        label="Address line 2"
        input-id="partnerAddress2"
        placeholder="(Optionnal)"
        class="col"
      />
    </div>

    <!-- Geolocalization -->
    <!-- <div class="row mb-4">
      <div class="form-group col-12 mb-0">
        <GmapAutocomplete @place_inputd="setPlace" />
      </div>

      <GmapMap :center="{ lat: 50.63289494409323, lng: 3.0360703468322647 }" :zoom="7" map-type-id="terrain" style="height: 350px">
        <GmapCluster :zoomOnClick="true">
          <GmapMarker v-for="(m, index) in markers" :key="index" :position="m.position" :draggable="true" @dragend="updateCoordinates($event.latLng)" />
        </GmapCluster>
      </GmapMap>
    </div> -->

    <!-- ZipCode & City-->
    <div class="row mb-3">
      <FormGroupInput v-model="syncedPartnerForm.Partner.ZipCode" label="Zip code" input-id="partnerZipCode" placeholder="Zip code" class="col" />
      <FormGroupInput v-model="syncedPartnerForm.Partner.City" label="City" input-id="partnerCity" placeholder="City" class="col" />
    </div>

    <!-- District & Country-->
    <div class="row mb-3">
      <!-- <div class="form-group col-6 mb-0">
        <label class="font-weight-bold" v-translate for="partnerDistrict">District</label>
        <b-form-input
          id="partnerDistrict"
          v-model="syncedPartnerForm.Partner.District"
          debounce="0"
          :placeholder="$gettext('District')"
          size="lg"
        ></b-form-input>
        <b-form-text id="partnerCountry-help" v-translate>Choose the district of the partner.</b-form-text>
      </div> -->
      <!-- <div class="form-group col-6 mb-0">
        TODO > Select
        <label class="font-weight-bold" for="partnerCountry">><translate>Country</translate> <span class="required">*</span></label>
        <b-form-input
          id="partnerCountry"
          v-model="syncedPartnerForm.Partner.CountryISO"
          :placeholder="$gettext('Country')"
          required
          size="lg"
          @input="
            $emit('update', {
              CountryISO: syncedPartnerForm.Partner.CountryISO
            })
          "
        ></b-form-input>
      </div> -->

      <div class="form-group col mb-0">
        <label class="font-weight-bold" for="partnerCountry"><translate>Country</translate> <span class="required">*</span></label>
        <Multiselect
          id="partnerCountry"
          v-model="Country"
          :options="CountryISO"
          label="Name"
          track-by="ISO2"
          placeholder="Select a country..."
          @select="
            (selectedOption, id) => {
              $emit('update', {
                CountryISO: selectedOption.ISO2,
              });
            }
          "
          @remove="
            (removedOption, id) => {
              $emit('update', {
                CountryISO: '',
              });
            }
          "
        ></Multiselect>
        <!--  :allow-empty="false" -->
        <b-form-text id="partnerCountry-help" v-translate>Choose the country of the partner.</b-form-text>
      </div>
    </div>

    <div class="row">
      <FormGroupInput v-model="syncedPartnerForm.PartnerDetails.Phone" label="Phone" input-id="partnerPhone" placeholder="Phone" class="col" />
      <FormGroupInput v-model="syncedPartnerForm.PartnerDetails.EMail" label="Email" input-id="partnerEmail" placeholder="Email" class="col" />
    </div>

    <!-- DEV -->
    <b-card class="mt-3" header="Data" v-if="showDataDebug">
      <pre class="m-0">{{ syncedPartnerForm }}</pre>
    </b-card>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapGetters } from "vuex";
import FormGroupInput from "@/view/content/neoopatrace/components/Inputs/FormGroupInput.vue";

import statics from "@/core/statics/statics.js";

import Multiselect from "vue-multiselect";
//import GmapCluster from "vue2-google-maps/dist/components/cluster";

export default {
  name: "wizard-partnerContact",
  props: {
    // Form
    syncedPartnerForm: Object,
    // Others PartnersReference
    /**@type {string[]} */
    partnersReferences: [],
  },
  components: {
    Multiselect,
    //GmapCluster
    FormGroupInput,
  },
  data() {
    return {
      // Preferences
      showHelpDescriptions: statics.showHelpDescriptions,
      showDataDebug: statics.showDataDebug,
      // Statics
      partnerMainRoleTypes: statics.partnerMainRoleTypes,
      // GmapCluster
      // markers: [],
      // place: null,
      // coordinates: undefined,
      // currentPlace: null
      // Data
      Country: {},
    };
  },
  computed: {
    ...mapGetters(["getCountries"]),
    CountryISO() {
      return this.getCountries;
    },
    isDuplicateReference() {
      // Vérifie si la référence du partenaire actuel est un doublon
      const currentReference = this.syncedPartnerForm.Partner.Reference;
      const currentPartnerID = this.syncedPartnerForm.Partner.PartnerID;

      // Vérifie si une autre référence identique existe
      return this.partnersReferences.some((partner) => partner.Reference === currentReference && partner.PartnerID !== currentPartnerID);
    },
  },
  created() {
    // this.PartnerID = this.$route.params.PartnerID;
    // this.GlobalID = this.$route.params.GlobalID;
  },
  methods: {
    // GmapCluster
    // updateCoordinates(location) {
    //   this.markers = [
    //     {
    //       position: {
    //         lat: location.lat(),
    //         lng: location.lng()
    //       }
    //     }
    //   ];
    //   console.log(this.markers);
    // },
    // setPlace(place) {
    //   this.currentPlace = place;
    // },
    // addMarker() {
    //   if (this.currentPlace) {
    //     this.marker = {
    //       lat: this.currentPlace.geometry.location.lat(),
    //       lng: this.currentPlace.geometry.location.lng()
    //     };
    //     this.center = this.marker;
    //   }
    //   return true;
    // }

    setCountryValue() {
      if (typeof this.CountryISO !== "undefined" && typeof this.syncedPartnerForm.Partner.CountryISO !== "undefined")
        this.Country = this.CountryISO.filter((c) => c.ISO2 == this.syncedPartnerForm.Partner.CountryISO);
    },
  },
  watch: {
    CountryISO: {
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function () {
        console.log("Watch CountryISO ::", this.CountryISO, this.syncedPartnerForm.Partner.CountryISO, this.Country);

        if (typeof this.CountryISO !== "undefined" && typeof this.syncedPartnerForm.Partner.CountryISO !== "undefined") this.setCountryValue();
      },
    },

    //syncedPartnerForm: function () {
    syncedPartnerForm: {
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      deep: true,
      handler: function () {
        console.log("Watch syncedPartnerForm CountryISO ::", this.CountryISO, this.syncedPartnerForm.Partner.CountryISO, this.Country);

        if (typeof this.CountryISO !== "undefined" && typeof this.syncedPartnerForm.Partner.CountryISO !== "undefined") this.setCountryValue();
      },
    },
    "syncedPartnerForm.Partner.Reference": {
      immediate: true,
      handler(newReference, oldReference) {
        if (newReference !== oldReference) {
          if (this.isDuplicateReference) {
            console.warn("La référence du partenaire est un doublon :", newReference);
          }
        }
      },
    },
  },
  mounted() {
    // if (typeof this.CountryISO !== "undefined" && typeof this.syncedPartnerForm.Partner.CountryISO !== "undefined")
    //   this.Country = this.CountryISO.filter((c) => c.ISO2 == this.syncedPartnerForm.Partner.CountryISO);
  },
};
</script>

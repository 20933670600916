<template>
  <div class="card card-custom card-transparent" :class="cardAdditionnalClasses">
    <!-- DEV FIX ? this one works  -->
    <!-- <wizardPartnerContact :syncedPartnerForm="syncedPartnerForm"></wizardPartnerContact> -->
    <!-- DEV FIX ? this one works  -->

    <!-- DEV FIX ? this one works  -->
    <!-- <wizardPartnerContact :syncedPartnerForm="syncedPartnerForm" @update="emitData"></wizardPartnerContact> -->
    <!-- DEV FIX ? this one works  -->

    <!-- DEV FIX ? this one don't work ( if no debounced )   -->
    <!-- <component v-bind:is="currentTabComponentFile" :syncedPartnerForm="syncedPartnerForm" @update="emitData"></component> -->
    <!-- DEV FIX ? this one don't work  -->

    <!--begin: Card header-->
    <div v-if="showTitle == true" class="card-header border-0 py-5">
      <h3 class="card-title">
        <span class="card-label font-weight-bolder font-size-h6">{{ title }}</span>
      </h3>
    </div>
    <!--end: Card header-->

    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div class="wizard wizard-neoopatrace-tab" id="wizard_neoopatrace_tab" data-wizard-state="step-first" data-wizard-clickable="false" :style="passCssVars">
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <!--begin: Wizard Steps-->
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              class="wizard-step"
              data-wizard-type="step"
              :data-wizard-state="currentTab === index ? 'current' : ''"
            >
              <div class="wizard-wrapper p-4 px-6">
                <div class="wizard-number">{{ index + 1 }}</div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    {{ tab }}
                  </div>
                  <div class="wizard-desc">
                    {{ tabsDescription[index] }}
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Steps-->
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center p-8 h-100">
              <div :class="formColumnsClasses.length > 0 ? formColumnsClasses : 'col-xl-12 col-xxl-8'">
                <!--begin: Wizard Form-->
                <form class="form" id="kt_form">
                  <!--begin: Wizard Steps -->
                  <div
                    v-for="(tab, index) in tabsTemplate"
                    :key="index"
                    :data-wizard-state="currentTab === index ? 'current' : ''"
                    data-wizard-type="step-content"
                    class="pb-5"
                  >
                    <div v-if="showDescription == true" class="mb-10 font-weight-bold text-dark">
                      {{ tabsDescription[index] }}
                    </div>

                    <!-- DEV FIX ? this direct one work ( if no debounced )   -->
                    <wizardPartnerContact
                      v-show="tab === 'wizard-partnerContact'"
                      :syncedPartnerForm="syncedPartnerForm"
                      :partnersReferences="partnersReferences"
                      @update="emitData"
                    ></wizardPartnerContact>
                    <wizardPartnerIdentification
                      v-show="tab === 'wizard-partnerIdentification'"
                      :syncedPartnerForm="syncedPartnerForm"
                      @update="emitData"
                    ></wizardPartnerIdentification>
                    <wizardPartnerAlert v-show="tab === 'wizard-partnerAlert'" :syncedPartnerForm="syncedPartnerForm" @update="emitData"></wizardPartnerAlert>
                    <!-- DEV FIX ? this one work ( if no debounced )   -->

                    <!-- DEV FIX ? this direct one work ( if no debounced )   -->
                    <!-- <b-form-input
                      id="partnerName"
                      v-model="syncedPartnerForm.Partner.Name"
                      :placeholder="$gettext('Name')"
                      required
                      size="lg"
                      class="font-size-h2"
                    ></b-form-input> -->
                    <!-- DEV FIX ? this one work ( if no debounced )   -->

                    <!-- DEV FIX ? this one don't work ( if no debounced )   -->
                    <!-- {{ index }}
                    {{ tab }}
                    
                    <keep-alive>
                      <component
                        v-if="currentTab === index"
                        :key="tab"
                        v-bind:is="currentTabComponentFile"
                        :syncedPartnerForm="syncedPartnerForm"
                        @update="emitData"
                      ></component>
                    </keep-alive>

                    <keep-alive>
                      <component
                        v-if="currentTab === index"
                        :key="tab"
                        v-bind:is="currentTabComponentFile"
                        :syncedPartnerForm="syncedPartnerForm"
                        @update="emitData"
                      ></component>
                    </keep-alive>

                    <component
                      v-if="currentTab === index"
                      :key="tab"
                      v-bind:is="currentTabComponentFile"
                      :syncedPartnerForm="syncedPartnerForm"
                      @update="emitData"
                    ></component>

                    <component :key="index" v-bind:is="currentTabComponentFile" :syncedPartnerForm.sync="syncedPartnerForm"></component> -->

                    <!-- <keep-alive>
                      <component v-bind:is="currentTabComponentFile" :syncedPartnerForm="syncedPartnerForm" @update="emitData"></component>
                    </keep-alive> -->
                    <!-- DEV FIX ? this one don't work ( if no debounced )   -->
                  </div>
                  <!--end: Wizard Steps -->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-8">
                    <div class="mr-2">
                      <!-- Previous -->
                      <b-button variant="secondary" size="lg" class="font-weight-bolder --text-uppercase" data-wizard-type="action-prev" v-translate>
                        Previous
                      </b-button>

                      <!-- Reset -->
                      <b-button
                        v-if="false"
                        variant="outline-secondary"
                        size="lg"
                        v-on:click="onResetForm"
                        :disabled="isResetting"
                        class="font-weight-bolder --text-uppercase ml-2"
                        data-wizard-type="action-reset"
                      >
                        <b-spinner v-if="isResetting === true" small type="grow" class="mr-2"></b-spinner>
                        {{ syncedPartnerForm.action === "create" ? $gettext("Reset") : $gettext("Cancel") }}
                      </b-button>
                    </div>
                    <div>
                      <!-- Submit -->
                      <b-button
                        variant="success"
                        size="lg"
                        v-on:click="onSubmitForm"
                        :disabled="isSubmitting"
                        class="font-weight-bolder --text-uppercase"
                        data-wizard-type="action-submit"
                      >
                        <b-spinner v-if="isSubmitting === true" small type="grow" class="mr-2"></b-spinner>
                        {{ syncedPartnerForm.action === "create" ? $gettext("Submit") : $gettext("Edit") }}
                      </b-button>

                      <!-- Next -->
                      <b-button variant="primary" size="lg" class="font-weight-bolder --text-uppercase" data-wizard-type="action-next" v-translate>
                        Next
                      </b-button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/neoopatrace/pages/wizard/wizard-neoopatrace.scss";
.wizard.wizard-neoopatrace-tab .wizard-nav .wizard-steps .wizard-step {
  -ms-flex: 0 0 calc(calc(100% / var(--tabsNumber)) - 0.25rem);
  flex: 0 0 calc(calc(100% / var(--tabsNumber)) - 0.25rem);
  width: calc(calc(100% / var(--tabsNumber)) - 0.25rem);
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
//import Swal from "sweetalert2";
// import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

import wizardPartnerContact from "@/view/pages/wizard/wizardcontent/wizard-partnerContact.vue";
import wizardPartnerIdentification from "@/view/pages/wizard/wizardcontent/wizard-partnerIdentification.vue";
import wizardPartnerAlert from "@/view/pages/wizard/wizardcontent/wizard-partnerAlert.vue";

import { RESET_PARTNER_DRAFT } from "@/core/services/store/neoopatrace/datas.module";

// https://vuejs.org/v2/guide/components-dynamic-async.html
// https://forum.vuejs.org/t/dynamic-components-with-dynamic-imports/20703/12
// https://stackoverflow.com/questions/61935450/vue-is-it-possible-to-use-a-prop-for-styling-scss-sass
// https://vuex.vuejs.org/guide/state.html#the-mapstate-helper
// https://codesandbox.io/s/github/vuejs/vuejs.org/tree/master/src/v2/examples/vue-20-dynamic-components?file=/index.html
// https://stackoverflow.com/questions/43074144/vue-js-input-field-loses-its-focus-after-entry-of-one-character

export default {
  name: "wizard_neoOpatrace",
  props: {
    title: String,
    tabs: Array,
    tabsDescription: Array,
    tabsTemplate: Array,
    showTitle: Boolean,
    showDescription: Boolean,
    cardAdditionnalClasses: String,
    formColumnsClasses: String,
    // https://codesandbox.io/embed/vue-template-m0gqv?fontsize=14
    syncedPartnerForm: Object,
  },
  components: {
    wizardPartnerContact,
    wizardPartnerIdentification,
    wizardPartnerAlert,
  },
  mounted() {
    this.updatePartnersReferences();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Wizard", route: "wizard-1" }, { title: "Wizard-neoOpatrace" }]);

    // Initialize form wizard
    const wizard = new KTWizard("wizard_neoopatrace_tab", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on(
      "beforeChange",
      function (wizardObj) {
        // validate the form and use below function to stop the wizard's step
        console.log("#beforeChange currentTab");
        console.log(this.currentTab, wizardObj);
      }.bind(this)
    );

    // Change event
    wizard.on(
      "change",
      function (wizardObj) {
        // wizardObj.stop(); >> Stop navigation
        let goNext = false;

        console.log("#change currentTab");
        console.log("old::", this.currentTab);
        console.log("newStep::", wizardObj.newStep);

        // setTimeout(() => {
        //   wizardObj.goTo(1);
        // }, 5000);

        goNext = this.checkForm();

        if (goNext === true) this.currentTab = wizardObj.newStep - 1;
        else return false;
        console.log("new::", this.currentTab);

        // Scroll to top animation
        // setTimeout(() => {
        //   KTUtil.scrollTop();
        // }, 500);
      }.bind(this)
    );
  },
  data() {
    return {
      currentTab: 0,
      tabsLength: this.tabs.length,
      // Submit
      isSubmitting: false,
      isResetting: false,
      partnersReferences: [],
    };
  },
  computed: {
    //...mapState("tabsTemplate"),
    // currentTabComponentFile() {
    //   // This is how to get the correct component template to load
    //   return this.importComponent(this.tabsTemplate[this.currentTab]);
    // },
    passCssVars() {
      return {
        /* Variables to pass to scss */
        "--tabsNumber": this.tabsLength,
      };
    },
    ...mapGetters(["getPartners"]),
    isDuplicateReference() {
      // Vérifie si la référence du partenaire actuel est un doublon
      const currentReference = this.syncedPartnerForm.Partner.Reference;
      const currentPartnerID = this.syncedPartnerForm.Partner.PartnerID;

      // Vérifie si une autre référence identique existe
      return this.partnersReferences.some((partner) => partner.Reference === currentReference && partner.PartnerID !== currentPartnerID);
    },
  },
  methods: {
    checkForm() {
      switch (this.currentTab) {
        case 0:
          console.log("It is the first step");
          if (
            !!this.syncedPartnerForm.Partner?.Name &&
            !!this.syncedPartnerForm.Partner?.MainRole &&
            !!this.syncedPartnerForm.Partner?.CountryISO &&
            !!this.syncedPartnerForm.Partner?.Reference
            /*typeof this.syncedPartnerForm.PartnerDetails.EMail !== "undefined" &&
            this.syncedPartnerForm.PartnerDetails.EMail !== ""*/
          ) {
            if (this.isDuplicateReference) {
              window.swalAlert.fire(
                this.$gettext("Please fill in a different reference"),
                this.$gettext("The partner reference is a duplicate on group."),
                "error"
              );
            } else {
              return true;
            }
          } else {
            window.swalAlert.fire(
              this.$gettext("Please fill all required fields"),
              this.$gettext("Some required information are missing in the first step."),
              "error"
            );
          }
          break;
        case 1:
          console.log("It is the second step");
          const rangeOfLatitude = 90;
          const rangeOfLongitude = 180;
          const lat = this.syncedPartnerForm.PartnerDetails.Latitude ?? "";
          const lon = this.syncedPartnerForm.PartnerDetails.Longitude ?? "";

          // Fonction pour vérifier si une valeur est un nombre flottant valide
          const isValidFloat = (value) => !isNaN(parseFloat(value)) && isFinite(value);
          if (lat === "" && lon === "") {
            return true; // Les deux champs peuvent être vides
          } else if ((lat === "" && lon !== "") || (lat !== "" && lon === "")) {
            window.swalAlert.fire(
              this.$gettext("Invalid coordinates"),
              this.$gettext("Both latitude and longitude must be filled or both must be empty."),
              "error"
            );
          } else if (!isValidFloat(lat) || !isValidFloat(lon)) {
            window.swalAlert.fire(this.$gettext("Invalid input"), this.$gettext("Latitude and longitude must be valid numbers."), "error");
          } else if (Math.abs(parseFloat(lat)) > rangeOfLatitude || Math.abs(parseFloat(lon)) > rangeOfLongitude) {
            window.swalAlert.fire(
              this.$gettext("Out of range"),
              this.$gettext("Latitude must be between -90 and 90, and longitude between -180 and 180."),
              "error"
            );
          } else {
            return true; // Toutes les conditions sont remplies
          }
          break;
        case 2:
          console.log("It is the third step");
          let ErrorsInMail = false;
          let ErrorsInQuantity = false;
          if (this.syncedPartnerForm.PalletsAlerts.length > 0) {
            // Check
            this.syncedPartnerForm.PalletsAlerts.forEach((a) => {
              console.log("It is the third step", a, a.MaxQuantity);
              if (typeof a.AlertEMails === "undefined" || a.AlertEMails.length == 0) ErrorsInMail = true;
              if (typeof a.MaxQuantity === "undefined" || a.MaxQuantity == "" || Number(a.MaxQuantity) == 0) ErrorsInQuantity = true;
            });

            // swalAlert
            if (ErrorsInQuantity === true)
              window.swalAlert.fire(
                this.$gettext("Please fill a quantity > 0"),
                this.$gettext("Some required information are wrong in the third step."),
                "error"
              );
            if (ErrorsInMail === true)
              window.swalAlert.fire(
                this.$gettext("Please fill all required fields"),
                this.$gettext("Some required information are missing in the third step."),
                "error"
              );

            // Then action
            if (ErrorsInQuantity === false && ErrorsInMail === false) return true;
          } else return true;

          break;
        default:
          return false;
      }
      return false;
    },
    emitData(data) {
      console.log("emitData::", data);

      // Merge datas
      let merged = { ...this.syncedPartnerForm, ...data };

      // Toggle isActive attribute
      if (Object.keys(data)[0] === "isActive") {
        if (data.isActive != merged.Partner.Attributes.includes("isActive")) merged.Partner.Attributes = _.xor(merged.Partner.Attributes, ["isActive"]); // Toggle value
      }

      // Merge CountryISO
      if (Object.keys(data)[0] === "CountryISO") {
        console.log("EMIT here CountryISO");
        if (data.CountryISO != merged.Partner.CountryISO) merged.Partner.CountryISO = data.CountryISO; // Change value
      }

      // Merge PalletsAlert
      if (Object.keys(data)[0] === "PalletID" && Object.keys(data)[1] === "PalletsAlertsIndex") {
        if (data.PalletID != merged.PalletsAlerts[data.PalletsAlertsIndex].PalletID) merged.PalletsAlerts[data.PalletsAlertsIndex].PalletID = data.PalletID; // Change value
      }
      console.log("EMIT here CountryISO", merged.Partner.CountryISO);

      // Emit
      this.$emit("update:syncedPartnerForm", merged);
    },
    resetData() {
      let data = {
        action: "create",
        Partner: { Attributes: ["isActive"] },
        PartnerDetails: {},
        PalletsAlerts: [],
      };
      this.$emit("update:syncedPartnerForm", data);
    },
    // importComponent(path) {
    //   // This is how to import the correct component
    //   return () => import(`./wizardcontent/${path}.vue`);
    // },
    // Form
    onSubmitForm: function (event) {
      console.log(JSON.stringify(this.syncedPartnerForm));
      event.preventDefault();

      // Const
      this.isSubmitting = true;
      let actionTitle = this.syncedPartnerForm.action === "create" ? this.$gettext("Create") : this.$gettext("Edit");
      let actionMessage = this.syncedPartnerForm.action === "create" ? this.$gettext("submitted") : this.$gettext("edited");

      // Check form before submit
      let goSubmit = this.checkForm();
      console.log("goSubmit::", goSubmit);

      if (goSubmit === true)
        // Post Partner
        (async () => {
          let response = null;
          let errorStatus = null;
          let errorData = null;

          try {
            if (this.syncedPartnerForm.PartnerDetails?.Longitude === "" && this.syncedPartnerForm.PartnerDetails?.Latitude === "") {
              delete this.syncedPartnerForm.PartnerDetails.Longitude;
              delete this.syncedPartnerForm.PartnerDetails.Latitude;
            }
            response = await ApiService.post(
              "/partner?ID=" + (typeof this.syncedPartnerForm.Partner.PartnerID !== "undefined" ? this.syncedPartnerForm.Partner.PartnerID : "0"),
              this.syncedPartnerForm
            );
          } catch (error) {
            console.error("Error response:");
            console.error(error.response.data); // ***
            console.error(error.response.status); // ***
            console.error(error.response.headers); // ***

            errorStatus = error.response.status;
            errorData = error.response.data.errors.join(", ");
          } finally {
            console.log(response);
            console.log(errorStatus);
            this.isSubmitting = false;

            if (response === null && errorStatus !== null) {
              // Error
              window.swalAlert.fire({
                title: errorStatus,
                html: "Damned, we are sorry, we have an error..." + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                footer: '<a href="mailto:support@opalean.fr">' + this.$gettext("Need to contact support ?") + "</a>",
                heightAuto: false,
              });
            } else if (response !== null && response.status === 200) {
              // Success, so reset Draft
              this.$store.dispatch(RESET_PARTNER_DRAFT).then(() => {});

              // Re-route to list
              this.$router.push({
                name: "route.partners.list",
                replace: true,
              });

              // Success
              window.swalToast
                .fire({
                  title: actionTitle + " Partner",
                  html: this.$gettext("The partner has been successfully") + "<em>" + actionMessage + "</em> !",
                  icon: "success",
                  timer: 1200,
                  confirmButtonClass: "btn btn-secondary",
                  footer: '<a href="/#/partners/list">' + this.$gettext("Back to list") + "</a>",
                })
                .then(() => {
                  // this.$router.push({
                  //   name: "route.partners.list",
                  //   replace: true,
                  // });
                });
            }
          }
        })();
      else this.isSubmitting = false;

      // Error
    },
    onResetForm(event) {
      event.preventDefault();
      this.isResetting = true;

      // const swalMixin = Swal.mixin({
      //   customClass: {
      //     confirmButton: "btn btn-success",
      //     cancelButton: "btn btn-outline-secondary",
      //   },
      //   buttonsStyling: false,
      // });

      window.swalAction
        .fire({
          title: this.$gettext("Are you sure ?"),
          text: this.$gettext("You won't be able to revert this!"),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: this.$gettext("Reset"),
          cancelButtonText: this.$gettext("Continue"),
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isResetting = false;

            // Reset our form values
            this.resetData();
            // Message
            // swalMixin.fire(this.$gettext("Resetted"), this.$gettext("Your form has been resetted."), "success");
            window.swalToast.fire({
              title: this.$gettext("Resetted"),
              html: this.$gettext("Your form has been resetted."),
              timer: 1200,
              timerProgressBar: true,
              icon: "success",
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === window.Swal.DismissReason.cancel
          ) {
            this.isResetting = false;

            //swalMixin.fire(this.$gettext("Cancelled"), this.$gettext("You can keep editing your form."), "success");
          }
        });
    },
    updatePartnersReferences() {
      // Met à jour le tableau avec les références des partenaires
      this.partnersReferences = this.getPartners.map((partner) => ({
        PartnerID: partner.PartnerID,
        Reference: partner.Reference,
      }));
    },
  },
  watch: {
    getPartners: {
      immediate: true,
      handler() {
        this.updatePartnersReferences();
      },
    },
  },
};
</script>
